import { createRouter, createWebHistory,createWebHashHistory } from 'vue-router'
import Index from '../views/Index.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Index
  },
  {
    path: '/lease',
    name: 'Lease',
    component: () => import("../views/lease.vue"),
  },
  {
    path: '/goodsDetail',
    name: 'goodsDetail',
    component: () => import("../views/goodsDetail.vue"),
  },
  {
    path: '/about',
    name: 'About',
    component: () => import("../views/about.vue"),
  },
  {
    path: '/question',
    name: 'Question',
    component: () => import("../views/question.vue"),
  },
  {
    path: '/infoDetailO',
    name: 'infoDetailO',
    component: () => import("../views/infoDetailO.vue"),
  },
  {
    path: '/infoDetailT',
    name: 'infoDetailT',
    component: () => import("../views/infoDetailT.vue"),
  },
  
  {
    path: '/infoDetailF',
    name: 'infoDetailF',
    component: () => import("../views/infoDetailF.vue"),
  },
  {
    path: '/shopApply',
    name: 'shopApply',
    component: () => import("../views/shopApply.vue"),
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
