import axios from 'axios'
import signKey from './signKey.js'

// 创建一个axios实例
const instance = axios.create({
    baseURL: 'https://api-lease.niannianzu.com',
    timeout: 15000,
})

// 封装请求拦截器（是请求被发出去之前）
instance.interceptors.request.use(function (config) {
    if(config.params){
        config.params = config.params
    }else{
        config.params = {}
    }
    let sign = signKey(config.params);
    // 分别将签名、密钥、时间戳 至请求头 
    if(sign) {
        config.headers["sign"] = sign.key
        config.params['timestamp'] = sign.time
    }
    
    
    return config
}, function (error) {
    return Promise.reject(error)
})

// 封装响应拦截器
instance.interceptors.response.use(function (response) {
    let res = null
    // HTTP状态码
    if (response.data.code == 200) {
        if (response && response.data) {
            // 业务状态码
            res = response.data
        }else{
            alert(response.data.msg)
        }
    } else if (response.data.code != 200) {
        if(response.data.code == 4000){
            window.location.href = '/'
        }
        // if (response.data.code == 4100) {
        //     alert("签名失败，请联系客服!");
        // }
        if(response.data.code == 5000 || response.data.code == 5020){
            alert(response.data.msg);
        }
        
    }
    return res
}, function (error) {
    return Promise.reject(error)
})

export default instance
