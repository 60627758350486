<template>
    <NavList />
    <div v-if="this.$utils == null">
        <div class="swiperWrap">
            <swiper class="swiperbox" :options="swiperOption">
                <swiper-slide>
                    <img class="swiper_img" src="http://lease-cdn.yuanwubao.com/webImg/202312/04swiper_1_web.png" alt="">
                </swiper-slide>
                <swiper-slide>
                    <img class="swiper_img" src="http://lease-cdn.yuanwubao.com/webImg/202312/04swiper_1_web.png" alt="">
                </swiper-slide>
                <swiper-slide>
                    <img class="swiper_img" src="http://lease-cdn.yuanwubao.com/webImg/202312/04swiper_1_web.png" alt="">
                </swiper-slide>
            </swiper>
            <div class="swiper-pagination pointSw" slot="pagination"></div>
        </div>
        <div class="containerWrap">
            <div class="flexWrap" style="justify-content: space-around;">
                <div class="item_box">
                    <div>严选二手</div>
                    <img class="imgBl" src="../assets/img/item_1.png" alt="严选二手">
                    <img class="imgHov" src="../assets/img/item_1_on.png" alt="严选二手">
                </div>
                <div class="item_box">
                    <div>闲置数码</div>
                    <img class="imgBl" src="../assets/img/item_2.png" alt="闲置数码">
                    <img class="imgHov" src="../assets/img/item_2_on.png" alt="闲置数码">
                </div>
                <div class="item_box">
                    <div>寄租赚钱</div>
                    <img class="imgBl" src="../assets/img/item_3.png" alt="寄租赚钱">
                    <img class="imgHov" src="../assets/img/item_3_on.png" alt="寄租赚钱">
                </div>
                <div class="item_box">
                    <div>优质订单</div>
                    <img class="imgBl" src="../assets/img/item_4.png" alt="优质订单">
                    <img class="imgHov" src="../assets/img/item_4_on.png" alt="优质订单">
                </div>
            </div>
            <div class="centerWrap">
                <div class="title_box">
                    <img src="../assets/img/title_5.png" alt="寄租赚钱">
                </div>
                <div class="center_box">
                    <div class="center_1">
                        <div class="center_1_title">什么是寄租？</div>
                        <div>将您的闲置数码产品委托到我们平台进行寄租，专业评估师团队将根据您寄租商品的价值与成色，
                            与您共同确认合理的设备评估价，产生的租金收益将在订单完结后到账。</div>
                    </div>
                    <div class="center_1" style="margin-top: 20px; margin-bottom: 30px;">
                        <div class="center_1_title">寄租保障</div>
                        <div>1、 我们承诺3天内为您租出商品座收租金</div>
                        <div>2、 一旦寄租的商品发生损坏、遗失或未按时收取当月租金等意外情况平台将进行先行赔付，最大限度保障您的合法权益。</div>
                    </div>
                </div>
            </div>
            <div class="stepWArp">
                <div class="title_box">
                    <img src="../assets/img/title_4.png" alt="租赁流程">
                </div>
                <div class="step_box">
                    <div class="box_item">
                        <img src="../assets/img/step_1.png" alt="">
                        <div class="box_tit">申请寄租</div>
                        <div>平台线上申请</div>
                        <div>审核通过即可寄租</div>
                    </div>
                    <img class="box_line" src="../assets/img/step_line.png" alt="">
                    <div class="box_item">
                        <img src="../assets/img/step_2.png" alt="">
                        <div class="box_tit">发布寄租</div>
                        <div>发布寄租商品，等待审核</div>
                        <div>通过邮寄到平台进行质检</div>
                    </div>
                    <img class="box_line" src="../assets/img/step_line.png" alt="">
                    <div class="box_item">
                        <img src="../assets/img/step_3.png" alt="">
                        <div class="box_tit">质检评估</div>
                        <div>顺丰上门邮寄方式邮寄</div>
                        <div>到平台，平台专业质检师进行</div>
                        <div>验货，确定设备评估价</div>
                    </div>
                    <img class="box_line" src="../assets/img/step_line.png" alt="">
                    <div class="box_item">
                        <img src="../assets/img/step_4.png" alt="">
                        <div class="box_tit">租期计算</div>
                        <div>寄租周期为一年，租金计算</div>
                        <div>公式：总租金=评估价*0.8；</div>
                        <div>每月租金=总租金/12期</div>
                    </div>
                    <img class="box_line" src="../assets/img/step_line.png" alt="">
                    <div class="box_item">
                        <img src="../assets/img/step_5.png" alt="">
                        <div class="box_tit">到期取回</div>
                        <div>到期后申请取回，提现收入，</div>
                        <div>支付寄租服务费即可退回物品</div>
                        <div>注：商品寄租中不支持取回</div>
                    </div>
                </div>
            </div>
            <div class="goodsWrap">
                <div class="title_box">
                    <img src="../assets/img/title_2.png" alt="租赁中心">
                </div>
                <div class="goods_box">
                    <div class="goods_item" v-for="(item, index) in leasCenter" :key="index" @click="getGoodsDetail(item)">
                        <img :src="$imgurl+item.media[0]" alt="">
                        <div class="goods_title">{{ item.title }}</div>
                        <div>
                            <span>日租价</span>
                            <span class="goods_price">￥{{ ((parseFloat(item.min_rice_range) / 365).toFixed(2)) }}/天</span>
                        </div>
                    </div>
                </div>
                <router-link class="moreGoods" to="/lease">
                    更多好物
                    <img src="../assets/img/moreGoods.png" alt="">
                </router-link>
            </div>
            <div class="infoWrap">
                <div class="title_box">
                    <img src="../assets/img/title_3.png" alt="租赁资讯">
                </div>
                <div class="info_box">
                    <div class="info_item">
                        <img class="info_img"
                        src="http://lease-cdn.yuanwubao.com/webImg/20230714/euUu8NUl9Pc4lGYYHba3.png" alt="">
                        <div class="info_title">大牌手机买不起？免押租机来帮你!</div>
                        <div class="info_disc">手机已经成为我们生活中不可或缺的产品，消费者对手机的需求越来越大，
                        很多人都是因为暂时资金不足而萌生了租赁手机的想法。</div>
                        <router-link to="/infoDetailO">
                            <img class="info_link" src="../assets/img/info_link.png" alt="">
                        </router-link>
                    </div>
                    <div class="info_item">
                        <img class="info_img"
                        src="http://lease-cdn.yuanwubao.com/webImg/20230714/8JT7B5uEVs5jD6IIVEPO.png" alt="">
                        <div class="info_title">怎么租手机？租手机靠不靠谱？</div>
                        <div class="info_disc">1、第一步打开你的支付宝或者微信，点击搜索框直接输入租手机等关键词，会弹出许多租手机商家，
                        选择一个评分高的小程序。</div>
                        <router-link to="/infoDetailT">
                            <img class="info_link" src="../assets/img/info_link.png" alt="">
                        </router-link>
                    </div>
                    <div class="info_item">
                        <img class="info_img"
                        src="http://lease-cdn.yuanwubao.com/webImg/20230714/3ORktjaVJHtdizBFtFad.png" alt="">
                        <div class="info_title">为什么越来越多的人开始租手机？</div>
                        <div class="info_disc">租手机这件事对消费者而言，最大的优势就是用较少的钱获得即时性满足，用租逻辑的话来说随用随租，
                        触手可得。</div>
                        <router-link to="/infoDetailH">
                            <img class="info_link" src="../assets/img/info_link.png" alt="">
                        </router-link>
                    </div>
                    <div class="info_item">
                        <img class="info_img"
                        src="http://lease-cdn.yuanwubao.com/webImg/20230714/9BQrHLopPbndwCv73wzl.png" alt="">
                        <div class="info_title">租机逾期会被起诉，是真的吗？</div>
                        <div class="info_disc">在数码租赁行业，为给客户提供更加便利的服务，用户可以通过芝麻信用免押来体验更多的数码产品。
                        但是有部分用户利用这种模式存在恶意逾期不还的情况，引起了不必要的法律纠纷。</div>
                        <router-link to="/infoDetailF">
                            <img class="info_link" src="../assets/img/info_link.png" alt="">
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="mobileWrap" v-else>
        <router-link class="applyHome" to="/shopApply">
            <img src="../assets/mobile/applyHome.png" alt="">
        </router-link>
        <div  class="swiperWrap">
            <swiper class="swiperbox" :options="swiperOption">
                <swiper-slide>
                    <img class="swiper_img" src="http://lease-cdn.yuanwubao.com/webImg/202312/04swiper_1_mobile.png" alt="">
                </swiper-slide>
                <swiper-slide>
                    <img class="swiper_img" src="http://lease-cdn.yuanwubao.com/webImg/202312/04swiper_1_mobile.png" alt="">
                </swiper-slide>
                <swiper-slide>
                    <img class="swiper_img" src="http://lease-cdn.yuanwubao.com/webImg/202312/04swiper_1_mobile.png" alt="">
                </swiper-slide>
            </swiper>
            <div class="swiper-pagination pointSw" slot="pagination"></div>
        </div>
        <div class="containerWrap">
            <div class="flexWrap" style="justify-content: space-between;">
                <div class="item_box">
                    <div>严选二手</div>
                    <img class="imgBl" src="../assets/img/item_1.png" alt="严选二手">
                </div>
                <div class="item_box">
                    <div>闲置数码</div>
                    <img class="imgBl" src="../assets/img/item_2.png" alt="闲置数码">
                </div>
                <div class="item_box">
                    <div>寄租赚钱</div>
                    <img class="imgBl" src="../assets/img/item_3.png" alt="寄租赚钱">
                </div>
                <div class="item_box">
                    <div>优质订单</div>
                    <img class="imgBl" src="../assets/img/item_4.png" alt="优质订单">
                </div>
            </div>
            <div class="centerWrap">
                <div class="title_box">
                    <img src="../assets/img/title_5.png" alt="寄租赚钱">
                </div>
                <div class="center_1">
                    <div class="center_1_title">什么是寄租？</div>
                    <div>将您的闲置数码产品委托到我们平台进行寄租，专业评估师团队将根据您寄租商品的价值与成色，
                        与您共同确认合理的设备评估价，产生的租金收益将在订单完结后到账。</div>
                </div>
                <div class="center_1" style="margin-top: 20px; margin-bottom: 30px;">
                    <div class="center_1_title">寄租保障</div>
                    <div>1、 我们承诺3天内为您租出商品座收租金</div>
                    <div>2、 一旦寄租的商品发生损坏、遗失或未按时收取当月租金等意外情况平台将进行先行赔付，最大限度保障您的合法权益。</div>
                </div>
            </div>
            <div class="stepWArp">
                <div class="title_box">
                    <img src="../assets/img/title_4.png" alt="寄租流程">
                </div>
                <div class="step_box">
                    <div class="box_item">
                        <img src="../assets/img/step_1.png" alt="">
                        <div class="box_tit">申请寄租</div>
                        <div>平台线上申请</div>
                        <div>审核通过即可寄租</div>
                    </div>
                    <div class="box_item">
                        <img src="../assets/img/step_2.png" alt="">
                        <div class="box_tit">发布寄租</div>
                        <div>发布寄租商品，等待审核</div>
                        <div>通过邮寄到平台进行质检</div>
                    </div>
                    <div class="box_item">
                        <img src="../assets/img/step_3.png" alt="">
                        <div class="box_tit">质检评估</div>
                        <div>顺丰上门邮寄方式邮寄</div>
                        <div>到平台，平台专业质检师进行</div>
                        <div>验货，确定设备评估价</div>
                    </div>
                    <div class="box_item">
                        <img src="../assets/img/step_4.png" alt="">
                        <div class="box_tit">租期计算</div>
                        <div>寄租周期为一年，租金计算</div>
                        <div>公式：总租金=评估价*0.8；</div>
                        <div>每月租金=总租金/12期</div>
                    </div>
                    <div class="box_item">
                        <img src="../assets/img/step_5.png" alt="">
                        <div class="box_tit">到期取回</div>
                        <div>到期后申请取回，提现收入，</div>
                        <div>支付寄租服务费即可退回物品</div>
                        <div>注：商品寄租中不支持取回</div>
                    </div>
                </div>
            </div>
            <div class="goodsWrap">
                <div class="title_box">
                    <img src="../assets/img/title_2.png" alt="租赁中心">
                </div>
                <div class="goods_box">
                    <div class="goods_item" v-for="(item, index) in leasCenter" :key="index" @click="getGoodsDetail(item)">
                        <img :src="$imgurl+item.media[0]" alt="">
                        <div class="goods_title">{{ item.title }}</div>
                        <div>
                            <span style="margin-right: 12px;">日租价</span>
                            <span class="goods_day">￥</span>
                            <span class="goods_price">{{ ((parseFloat(item.min_rice_range) / 365).toFixed(2)) }}</span>
                            <span class="goods_day">/天</span>
                        </div>
                    </div>
                </div>
                <router-link class="moreGoods" to="/lease">
                    更多好物
                    <img src="../assets/img/moreGoods.png" alt="">
                </router-link>
            </div>
            <div class="infoWrap">
                <div class="title_box">
                    <img src="../assets/img/title_3.png" alt="租赁资讯">
                </div>
                <div class="info_box">
                    <div class="info_item">
                        <img class="info_img"
                        src="http://lease-cdn.yuanwubao.com/webImg/20230714/euUu8NUl9Pc4lGYYHba3.png" alt="" >
                        <div class="info_right">
                            <div class="info_title">大牌手机买不起？免押租机来帮你!</div>
                            <div class="info_disc">手机已经成为我们生活中不可或缺的产品，消费者对手机的需求越来越大，
                            很多人都是因为暂时资金不足而萌生了租赁手机的想法。</div>
                            <router-link to="/infoDetailO">
                                <img class="info_link" src="../assets/mobile/info_link.png" alt="">
                            </router-link>
                        </div>
                    </div>
                    <div class="info_item">
                        <img class="info_img"
                        src="http://lease-cdn.yuanwubao.com/webImg/20230714/8JT7B5uEVs5jD6IIVEPO.png" alt="">
                        <div class="info_right">
                            <div class="info_title">怎么租手机？租手机靠不靠谱？</div>
                            <div class="info_disc">1、第一步打开你的支付宝或者微信，点击搜索框直接输入租手机等关键词，会弹出许多租手机商家，
                            选择一个评分高的小程序。</div>
                            <router-link to="/infoDetailT">
                                <img class="info_link" src="../assets/mobile/info_link.png" alt="">
                            </router-link>
                        </div>
                    </div>
                    <div class="info_item">
                        <img class="info_img"
                        src="http://lease-cdn.yuanwubao.com/webImg/20230714/3ORktjaVJHtdizBFtFad.png" alt="">
                        <div class="info_right">
                            <div class="info_title">为什么越来越多的人开始租手机？</div>
                            <div class="info_disc">租手机这件事对消费者而言，最大的优势就是用较少的钱获得即时性满足，用租逻辑的话来说随用随租，
                            触手可得。</div>
                            <router-link to="/infoDetailH">
                                <img class="info_link" src="../assets/mobile/info_link.png" alt="">
                            </router-link>
                        </div>
                    </div>
                    <div class="info_item">
                        <img class="info_img"
                        src="http://lease-cdn.yuanwubao.com/webImg/20230714/9BQrHLopPbndwCv73wzl.png" alt="">
                        <div class="info_right">
                            <div class="info_title">租机逾期会被起诉，是真的吗？</div>
                            <div class="info_disc">在数码租赁行业，为给客户提供更加便利的服务，用户可以通过芝麻信用免押来体验更多的数码产品。
                            但是有部分用户利用这种模式存在恶意逾期不还的情况，引起了不必要的法律纠纷。</div>
                            <router-link to="/infoDetailF">
                                <img class="info_link" src="../assets/mobile/info_link.png" alt="">
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <Foot/>
</template>

<script>
    import NavList from '@/components/navList.vue'
    import Foot from '@/components/foot.vue'

    export default {
        name: 'Home',
        data() {
            return{
                swiperOption: {
                    //分页
                    pagination: {
                        el: ".swiper-pagination",
                        clickable: true, // 允许点击小圆点跳转
                    },
                    //自动播放时间
                    autoplay: {
                        delay: 4000,
                        disableOnInteraction: false,
                    },
                    //切换速度
                    speed: 1000,
                    loop: true,
                },
                leasCenter: []
            }
        },
        components: {
            NavList,
            Foot
        },
        created() {
            this.$api.home().then(res => {
                this.leasCenter = res.data.leas_center
            })
        },
        methods: {
            getGoodsDetail(item){
                this.$router.push({ path: '/goodsDetail', query: { id: item.id } })
            }
        }
    }
</script>
<style lang="less" scoped>
    .swiperWrap{
        position: relative;
        width: 100%;
        height: 590px;
        background: url('http://lease-cdn.yuanwubao.com/webImg/20230714/bannerImg_web.png');
        background-size: 100% 590px;

        .swiperbox{
            position: absolute;
            top: 72px;
            left: 190px;
            width: 687px;
            height: 302px;
        }
        .swiper_img{
            width: 100%;
            height: 100%;
        }
        .swiper-pagination {
            position: absolute;
            bottom: 165px;
            left: 215px;
            width: 90px;
            height: 20px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-direction: row;
            text-align: center;
            transition: 300ms;
            transform: translate3d(0, 0, 0);
            z-index: 100;
        }
        .pointSw :deep(.swiper-pagination-bullet) {
            width: 14px;
            height: 14px;
            background: #fff;
            opacity: 1;
        }
        .pointSw :deep(.swiper-pagination-bullet-active) {
            width: 14px;
            height: 14px;
            border: 3px solid #97f7f0;
            background: #25C7BB;
        }
    }
    .mobileWrap{
        @media screen and (min-width: 768px) {
            .swiperWrap{
                height: 265px;
                background: url('http://lease-cdn.yuanwubao.com/webImg/20230714/bannerImg_web.png');
                background-size: 100% 100%;

                .swiperbox{
                    top: 24px;
                    left: 30px;
                    width: 438px;
                    height: 171px;
                }
                .swiper-pagination {
                    bottom: 40px;
                    left: 40px;
                    width: 70px;
                    height: 15px;
                }
                .pointSw :deep(.swiper-pagination-bullet) {
                    width: 12px;
                    height: 12px;
                }
                .pointSw :deep(.swiper-pagination-bullet-active) {
                    border: 3px solid #97f7f0;
                }
            }
            .containerWrap{
                padding: 0 60px;

                .info_img{
                    width: 160px !important;
                    height: 160px !important;
                }
                .info_right{
                    flex: 1;
                    line-height: 2.8;
                }
            }
        }
        @media screen and (max-width: 767px) {
            .swiperWrap{
                height: 139px;
                background: url('http://lease-cdn.yuanwubao.com/webImg/20230714/bannerImg_mobile.png');
                background-size: 100% 100%;

                .swiperbox{
                    top: 12px;
                    left: 20px;
                    width: 216px;
                    height: 83px;
                }
                .swiper-pagination {
                    bottom: 20px;
                    left: 25px;
                    width: 30px;
                    height: 10px;
                }
                .pointSw :deep(.swiper-pagination-bullet) {
                    width: 4px;
                    height: 4px;
                }
                .pointSw :deep(.swiper-pagination-bullet-active) {
                    border: 2px solid #97f7f0;
                }
            }
            .containerWrap{
                padding: 20px 14px 0;

                .info_img{
                    width: 114px !important;
                    height: 114px !important;
                }
                .info_right{
                    flex: 1;
                }
            }
        }
        .applyHome{
            position: fixed;
            bottom: 200px;
            right: 12px;
            width: 93px;
            height: 99px;
            img{
                width: 100%;
                height: 100%;
            }
        }
        .containerWrap{
            box-sizing: border-box;
            .item_box{
                width: 86px;
                height: 86px;
                color: #171717;
                font-size: 14px;

                img{
                    display: block;
                    width: 30px;
                    height: 30px;
                    margin: 10px auto 0;
                }

            }
            .title_box{
                padding-top: 10px;
                padding-bottom: 20px;

                img{
                    width: 154px;
                    height: 46px;
                }
            }
            .center_1{
                width: 100%;
                padding: 12px 16px;
                color: #3A3A3A;
                font-size: 14px;
                line-height: 1.6;
                background-color: #ECFBFA;
                border-radius: 16px;
                box-sizing: border-box;
            }
            .center_1_title{
                margin-bottom: 6px;
                font-size: 16px;
                color: #303030;
                font-weight: bold;  
            }
            .step_box{
                flex-wrap: wrap;
                margin-bottom: 20px;
                justify-content: space-between;

                .box_item{
                    width: 48%;
                    padding: 10px 2px;
                    color: #5A5A5A;
                    font-size: 12px;
                    text-align: center;
                    border-radius: 8px;
                    margin-bottom: 4%;
                    border: 1px solid #F6F6F6;
                    box-sizing: border-box;

                    img{
                        width: 32px;
                        height: 32px;
                    }
                    .box_tit{
                        font-size: 14px;
                        margin-bottom: 6px;
                    }
                }
            }
            .goodsWrap{
                margin-left: -14px;
                margin-right: -14px;
                padding-left: 14px;
                    padding-right: 14px;
                background: linear-gradient(180deg, #FFFFFF 0%, rgba(213,255,252,0.26) 67%, rgba(255,255,255,0.01) 100%);
                .goods_box{
                    justify-content: space-between;
                    box-sizing: border-box;

                    .goods_item{
                        width: 48%;
                        color: #3A3A3A;
                        font-size: 14px;
                        text-align: left;
                        padding: 10px !important;
                        border-radius: 8px;
                        margin-bottom: 4%;
                        box-shadow: 0px 0px 2px 1px rgba(0,0,0,0.08);

                        img{
                            display: block;
                            width: 130px;
                            height: 130px;
                            margin-bottom: 7px;
                        }
                        .goods_title{
                            color: #2E2E2E;
                            margin-bottom: 5px;
                        }
                        .goods_day{
                            color: #FF4848;
                            font-size: 10px;
                            font-weight: 400;
                        }
                        .goods_price{
                            color: #FF4848;
                            font-size: 18px;
                        }
                    }
                }
                .moreGoods{
                    margin-bottom: 26px;

                    img{
                        width: 7px;
                        height: 12px;
                        margin-left: 4px;
                        vertical-align: middle;
                    }
                }
            }
            .info_box{
                justify-content: flex-start;
                flex-direction: column;
                flex-wrap: nowrap;

                .info_item{
                    display: flex;
                    align-items: center;
                    width: 100%;
                    height: auto;
                    padding-right: 0;
                    padding-bottom: 0;
                    margin-bottom: 3%;
                    border-radius: 8px 0px 0px 8px;
                    box-sizing: border-box;

                    .info_img{
                        margin-bottom: 0;
                        border-radius: 8px;
                    }
                    .info_right{
                        padding-left: 12px;
                        box-sizing: border-box;
                    }
                    .info_title{
                        font-size: 14px;
                    }
                    .info_disc{
                        font-size: 12px;
                    }
                    .info_link{
                        float: right;
                        width: 49px;
                        height: 17px;
                    }

                }
            } 
        }
    }
    .containerWrap{
        .item_box{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 226px;
            height: 226px;
            cursor: pointer;
            color: #303030;
            font-size: 24px;

            img{
                width: 60px;
                height: 60px;
                margin-top: 30px;
            }
            .imgHov{
                display: none;
            }

            &:hover{
                border-radius: 24px;
                background: #fff;
                box-shadow: 0px 0px 10px 1px rgba(0,0,0,0.05);
                opacity: 0.8;
                color: #171717;
                font-weight: bold;

                .imgBl{
                    display: none;
                }
                .imgHov{
                    display: block;
                }
            }
        }
        .title_box{
            padding-top: 50px;
            padding-bottom: 50px;

            img{
                display: block;
                width: 210px;
                height: 65px;
                margin: 0 auto;
             }
        }
        .center_box{
            display: flex;
            align-items: center;
            justify-content: space-around;
            flex-direction: row;
            margin-bottom: 31px;
        }
        .center_1{
            width: 48%;
            padding: 12px 16px;
            color: #3A3A3A;
            font-size: 16px;
            line-height: 1.8;
            background-color: #ECFBFA;
            border-radius: 16px;
            box-sizing: border-box;
        }
        .center_1_title{
            margin-bottom: 6px;
            font-size: 18px;
            color: #303030;
            font-weight: bold;  
        }
        .step_box{
            display: flex;
            align-items: center;
            justify-content: space-around;
            flex-direction: row;
            margin-bottom: 31px;

            .box_item{
                color: #3A3A3A;
                font-size: 16px;
                text-align: center;
                line-height: 1.5;

                img{
                    width: 71px;
                    height: 71px;
                }
                .box_tit{
                    color: #303030;
                    font-size: 18px;
                    font-weight: bold;
                    margin-bottom: 16px;
                }
            }
            .box_line{
                width: 30px;
            }
        }
        .goodsWrap{
            background: linear-gradient(180deg, #FFFFFF 0%, rgba(213,255,252,0.1) 51%, rgba(255,255,255,0.01) 100%);
            .goods_box{
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: space-around;

                .goods_item{
                    width: 20%;
                    height: auto;
                    padding: 20px;
                    color: #303030;
                    font-size: 16px;
                    font-weight: bold;
                    text-align: center;
                    cursor: pointer;
                    box-sizing: border-box;

                    &:hover{
                        background-color: #fff;
                        box-shadow: 0px 2px 6px 1px rgba(0,0,0,0.08);
                        border-radius: 8px;
                    }
                    img{
                        display: block;
                        width: 180px;
                        height: 180px;
                        margin: 0 auto 15px;
                    }
                    .goods_title{
                        margin-bottom: 14px;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        -o-text-overflow:ellipsis;
                    }
                    .goods_price{
                        color: #CC3535;
                        font-size: 20px;
                    }
                }
            }
            .moreGoods{
                display: inline-block;
                width: 100%;
                color: #25C7BB;
                font-size: 14px;
                text-align: right;

                img{
                    width: 7px;
                    height: 12px;
                    margin-left: 4px;
                    vertical-align: middle;
                }
            }
        }
        .info_box{
            display: flex;
            align-items: center;
            justify-content: space-around;
            flex-direction: row;
            flex-wrap: wrap;

            .info_item{
                width: 24%;
                height: auto;
                padding-bottom: 22px;
                border-radius: 16px 16px 0px 0px;
                cursor: pointer;
                box-sizing: border-box;

                .info_img{
                    display: block;
                    width: 262px;
                    height: 262px;
                    margin-bottom: 20px;
                    border-radius: 16px;
                }
                .info_title{
                    color: #171717;
                    font-size: 16px;
                    font-weight: bold;
                    margin-bottom: 6px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    -o-text-overflow:ellipsis;
                }
                .info_disc{
                    color: #5A5A5A;
                    font-size: 14px;
                    margin-bottom: 10px;
                    overflow: hidden;
                    word-break: break-all;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                }
                .info_link{
                    width: 24px;
                    height: 24px;
                }

            }
        } 
    }
</style>
