import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueAwesomeSwiper from 'vue-awesome-swiper/src'
import * as api from './utils/api.js'
import './utils/rem.js'

import 'vue-awesome-swiper/node_modules/swiper/dist/css/swiper.css'
import 'swiper/css/bundle'
import '../src/assets/css/index.less'

const app = createApp(App)

app.config.globalProperties.$api = api
app.config.globalProperties.$imgurl = 'http://lease-cdn.yuanwubao.com'

app.config.globalProperties.$utils = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)

app.use(VueAwesomeSwiper).use(store).use(router).mount('#app')
app.config.productionTip = false
