<template>
    <div v-if="this.$utils == null" class="header">
        <div class="container">
            <img class="logoImg" src="../assets/img/logo.png"/>
            <ul class="container-left-ul">
                <li :class="activeName === item.activeUrl ? 'menu-item-active' : ''" v-for="(item, index) in leftMenuList" :key="index">
                    <div v-if="item.titleName == '支付宝小程序'">
                        {{ item.titleName }}
                        <img class="QR_code_img" src="http://lease-cdn.yuanwubao.com/webImg/20230714/QR_code_web.png"/>
                    </div>
                    <div v-else @click="toActiveMenuItem(item)">{{ item.titleName }}</div>
                </li>
            </ul>
        </div>
    </div>
    <div v-else class="header_2">
        <div class="container_2">
            <img class="logoImg_2" src="../assets/img/logo.png"/>
        
            <div class="container-right-ul" @click="dropDownShow = !dropDownShow">
                <img v-if="!dropDownShow" class="li_right_img" src="../assets/mobile/li_right.png"/>
                <img v-else class="li_right_img" src="../assets/mobile/cancel_nav.png"/>
            </div>
            <ul v-show="dropDownShow" class="dropdown">
                <li :class="activeName === item.activeUrl ? 'dropdown_item' : ''" v-for="(item, index) in leftMenuList"
                    :key="index" @click="toActiveMenuMobile(item)">{{ item.titleName }}</li>
            </ul>
        </div>
        
    </div>
    <div :class="this.$utils == null?'pad80':'pad44'"></div>
  </template>
  
  <script>
    export default {
        name: 'navList',
        Props: { 
            activeName: {
                type: String
            },
        },
        data () {
            return {
                dropDownShow: false, // 控制下拉菜单显示
                leftMenuList: [ // 左侧菜单内容
                    { activeName: 'Home', titleName: '首 页', activeUrl: '/' },
                    { activeName: 'Lease', titleName: '租赁中心', activeUrl: '/lease' },
                    { activeName: 'About', titleName: '关于我们', activeUrl: '/about' },
                    { activeName: 'Question', titleName: '常见问题', activeUrl: '/question' },
                    { activeName: 'ApplyX', titleName: '支付宝小程序'}
                ],
                activeName: this.$route.path // 导航栏激活名称
            }
        },
        methods: {
            toActiveMenuItem (item) { // 激活导航菜单
                this.activeName = item.activeUrl
                if(item.titleName != '支付宝小程序'){
                    this.$router.push(item.activeUrl)
                }
            },
            toActiveMenuMobile (item){
                this.activeName = item.activeUrl
                if(item.titleName == '支付宝小程序'){
                    window.location.href = 'https://ds.alipay.com/?scheme=alipays%3A%2F%2Fplatformapi%2Fstartapp%3FappId%3D2021003192665220%26page%3D'
                }else{
                    this.$router.push(item.activeUrl)
                }
                this.dropDownShow = false
            }
        }
    }
</script>

<style lang="less" scoped>
    .header{
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        width: 100%;
        height: 81px;
        padding: 0 12%;
        color:#303030;
        background-color: #fff;
        font-size: 16px;
        border-bottom: 1px solid #F6F6F6;
        box-sizing: border-box;
        z-index: 999;

        .container{
            display: flex;
            align-items: center;
            width: 100%;
            height: 100%;
            .logoImg{
                width: 76px;
                height: 54px;
                margin-right: 12%;
            }
            .container-left-ul{
                display: flex;
                align-items: center;
                flex: 1;
                height: 100%;

                li{
                    position: relative;
                    width: 132px;
                    height: 38px;
                    line-height: 38px;
                    text-align: center;
                    cursor: pointer;

                    &:hover{
                        color: #25C7BB;

                        .QR_code_img{
                            display: block;
                        }
                    }
                }
            }
            .QR_code_img{
                position: absolute;
                left: 50%;
                top: 53px;
                transform: translate(-50%,0%);
                display: none;
                width: 208px;
                height: 208px;
            }
        }
    }
    .header_2{
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        width: 100%;
        height: 45px;
        padding: 0 14px;
        color:#303030;
        background-color: #fff;
        font-size: 16px;
        box-sizing: border-box;
        border-bottom: 1px solid #F6F6F6;;
        z-index: 999;
        .container_2{
            display: flex;
            align-items: center;
            width: 100%;
            height: 100%;
            .logoImg_2{
                width: 38px;
                height: 27px;
                margin-right: auto;
            }

            .dropdown{
                position: absolute;
                top: 44px;
                right: 0;
                width: 156px;
                height: auto;
                padding-left: 44px;
                padding-bottom: 10px;
                background-color: #fff;
                line-height: 44px;
                color: #3A3A3A;
                font-size: 14px;
                font-weight: bold;
                box-sizing: border-box;
                border-radius: 0 0 8px 8px;
                box-shadow: 0px 3px 6px 1px rgba(0,0,0,0.1);

                .dropdown_item{
                    color: #25C7BB !important;
                }
            }
            .container-right-ul{
                display: block;
                height: 24px;
                .li_right_img{
                    width: 24px;
                    height: 24px;
                }
            }
        }
    }
    .menu-item-active{
        color: #fff;
        font-weight: bold;
        background-color: #25C7BB;
        border-radius: 20px;

        &:hover{
            color: #fff !important;
        }
    }
    .pad80{
        padding-top: 80px;
    }
    .pad44{
        padding-top: 44px;
    }  
</style>
  