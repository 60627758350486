<template>
    <img v-if="this.$utils == null" class="apply_img" src="http://lease-cdn.yuanwubao.com/webImg/20230728/applyFoot.png" @click="getShopApply()" alt="">
    <div style="height: 50px;" v-else></div>
    <div class="footWArap">
        <div v-if="this.$utils == null" class="box">
            <div class="lianxi_box">
                <div class="box_title">联系我们</div>
                <div>
                    <img class="box_img" src="../assets/img/phone.png" alt="">
                    <span>13714167140</span>
                </div>
                <div>
                    <img class="box_img" src="../assets/img/address.png" alt="">
                    <span>广东省深圳市龙华区龙胜时代大厦</span>
                </div>
            </div>
            <div class="lianxi_box">
                <div class="box_title">合作意向</div>
                <div>
                    <img class="box_img" src="../assets/img/applyImg.png" alt="">
                    <router-link class="toPage" to="/shopApply">商家入驻</router-link>
                </div>
            </div>
            <div class="lianxi_box" style="text-align: center;">
                <div class="box_title">关注我们</div>
                <img class="qrcode_img" src="http://lease-cdn.yuanwubao.com/webImg/20230714/qrcode_web.png" alt="">
            </div>
        </div>
        <div v-else class="box_2">
            <div class="box_title">联系我们</div>
            <div>
                <img class="box_img" src="../assets/img/phone.png" alt="">
                <span>13714167140</span>
            </div>
            <div>
                <img class="box_img" src="../assets/img/address.png" alt="">
                <span>广东省深圳市龙华区龙胜时代大厦</span>
            </div>
        </div>
        <div class="beian">年年租赁 
            <a target="_blank" href="https://beian.miit.gov.cn/">粤ICP备2023056545号-1</a>
             niannianzu.com</div>
    </div>
  </template>
  
  <script>
    export default {
        name: 'foot',
        methods: {
            getShopApply(){
                this.$router.push({ path: '/shopApply'})
            }
        },
    }
</script>

<style lang="less" scoped>
    .apply_img{
        display: block;
        width: 100%;
        height: 100px;
        margin-top: 50px;
    }
    .footWArap{
        width: 100%;
        height: auto;
        background-color: #2B2B2B;
        padding-bottom: 30px;
        box-sizing: border-box;

        .box{
            display: flex;
            justify-content: space-between;
            flex-direction: row;
            padding: 30px 20% 39px 10%;
            box-sizing: border-box;

            .lianxi_box{
                display: flex;
                flex-direction: column;
                color: #fff;
                line-height: 2.0;
                font-size: 14px;

                .box_img{
                    width: 20px;
                    height: 20px;
                    margin-right: 10px;
                    vertical-align: middle;
                }
                .toPage{
                    width: 110px;
                    color: #fff;
                }
                .qrcode_img{
                    width: 108px;
                    height: 108px;
                }
            }
        }
        .box_2{
            display: flex;
            flex-direction: column;
            padding: 16px 14px 25px;
            color: #fff;
            line-height: 2.0;
            font-size: 14px;
            box-sizing: border-box;

            .box_img{
                width: 16px;
                height: 16px;
                margin-right: 6px;
                vertical-align: middle;
            }
            .box_title{
                font-size: 14px;
            }
        }
        .box_title{
            font-size: 18px;
            color: #B9B9B9;
            font-weight: bold;
            margin-bottom: 15px;
        }
        .beian{
            text-align: center;
            color: #959595;
            font-size: 12px;
            a{
                color: #959595;
            }
        }
    }
</style>
  