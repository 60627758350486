import axios from "./request.js";

export function home(params){
    return axios({
        url:'app/leaseWeb/v1/Home',
        method:'GET',
        params
    })
}

export function productList(params){
    return axios({
        url:'app/leaseWeb/v1/Product/lists',
        method:'GET',
        params
    })
}

export function productDetaul(params){
    return axios({
        url:'app/leaseWeb/v1/Product/first',
        method:'GET',
        params
    })
}

export function productAlipayQrcode(params){
    return axios({
        url:'app/leaseWeb/v1/Product/getProductAlipayQrcode',
        method:'GET',
        params
    })
}

export function hotRent(params){
    return axios({
        url:'app/leaseWeb/v1/Product/hotRent',
        method:'GET',
        params
    })
}

export function shopApply(data){
    return axios({
        url:'app/leaseWeb/v1/Shop/apply',
        method:'POST',
        data
    })
}